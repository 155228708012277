import React from 'react';
import {Link} from "gatsby";

import tw, {styled} from 'twin.macro';
import {iState} from "@/state";
import {connect} from "react-redux";

const Wrapper = styled.div(() => [tw`flex flex-col h-full`]);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	title: string;
	unitPrice: number;
	productName: string;
	description: string;
	platformFeatures: string[];
	features: string[];
	disadvantages: string[];
	tags: string[];
}

const Products: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="bg-bright-background px-6 py-12 lg:p-12 text-center flex-1">
				<h3 className="text-4xl leading-12 font-extrabold text-primary-background sm:text-5xl sm:leading-12">{props.title}</h3>
				{props.tags.map((t, i) => (
					<span
						key={`tag-${i}`}
						className="mr-1 ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-teal-100 text-teal-800">
						{t}
					</span>
				))}
				<p className="mt-6 text-base leading-6 text-bright-text">{props.description}</p>

				<div className="mt-8">
					<div className="flex items-center">
						<b className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-green-400">
							{props.productName} Features
						</b>
						<div className="flex-1 border-t-2 border-gray-200"/>
					</div>
					<ul className="mt-8 lg:grid lg:grid-cols-1 xl:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
						{props.features.map((f, i) => (
							<li key={`feature-${i}`} className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
								<div className="flex-shrink-0">
									<svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<p className="ml-3 text-sm leading-5 text-bright-text">{f}</p>
							</li>
						))}
					</ul>
				</div>

				<div className="mt-8">
					<div className="flex items-center">
						<b className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-red-400">
							{props.productName} disadvantages
						</b>
						<div className="flex-1 border-t-2 border-gray-200"/>
					</div>
					<ul className="mt-8 lg:grid lg:grid-cols-1 xl:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
						{props.disadvantages.map((f, i) => (
							<li key={`disadvantage-${i}`}
							    className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
								<div className="flex-shrink-0">
									<svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<p className="ml-3 text-sm leading-5 text-bright-text">{f}</p>
							</li>
						))}
					</ul>
				</div>

				<div className="mt-8">
					<div className="flex items-center">
						<b className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-green-400">
							Our features
						</b>
						<div className="flex-1 border-t-2 border-gray-200"/>
					</div>
					<ul className="mt-8 lg:grid lg:grid-cols-1 xl:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
						{props.platformFeatures.map((f, i) => (
							<li key={`our-feature-${i}`} className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
								<div className="flex-shrink-0">
									<svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<p className="ml-3 font-bold text-sm leading-5 text-bright-text">{f}</p>
							</li>
						))}
					</ul>
				</div>

			</div>
			<div className="py-8 px-6 text-center bg-primary-100 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
				<p className="text-lg leading-6 font-medium text-primary-background">
					One time payment of just
				</p>
				<div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-primary-background">
					<span>${props.unitPrice}</span>
					<span
						className="ml-3 text-xl leading-7 font-medium text-bright-text">/ instance</span>
				</div>

				<div className="mt-6">
					<div className="rounded-md shadow">
						<Link
							to={`create/${props.productName}`}
							className="flex items-center justify-center px-5 py-4 border border-transparent text-xl leading-6 font-extrabold rounded-md text-primary-text bg-primary-800 hover:bg-primary-900 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
						>
							Create {props.title} server
						</Link>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};


const mapStateToProps = (state: iState) => {
	return {
		form: state.form,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Products);
