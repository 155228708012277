import React from 'react';

import tw, {styled, css, theme} from 'twin.macro';

import Products from "@/components/products";
import {PRICE} from "@/constants";

const Wrapper = styled.div(() => [tw``]);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

const Index: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="mt-12 grid gap-10 sm:mt-16 sm:space-y-0 md:grid md:grid-cols-2 sm:gap-4 lg:gap-16">
				<div className="w-full mx-auto rounded-lg shadow-lg overflow-hidden">
					<Products
						title={'Wireguard'}
						unitPrice={PRICE}
						productName={'wireguard'}
						description={
							'A relatively new VPN implementation that achieved popularity ' +
							'really fast due to its simplicity and performance. On most ' +
							'operating systems it is already part of the kernel which says ' +
							'a lot about it.'
						}
						platformFeatures={[
							'No logs',
							'Use your own server',
							'We store nothing',
							'One-time payment',
							'Use open-source tools',
							'Transparent process',
							'Privacy comes first',
							'Affordable'
						]}
						features={[
							'Extremely light',
							'Kernel implementation',
							'Actively maintained',
							'Open source',
							'Client applications',
							'Very good performance',
						]}
						disadvantages={[
							'Works only on UDP',
							'Lacks some features',
						]}
						tags={["speed", "performance"]}
					/>
				</div>

				<div className="w-full mx-auto rounded-lg shadow-lg overflow-hidden">
					<Products
						title={'OpenVPN'}
						unitPrice={PRICE}
						productName={'openvpn'}
						description={
							'The most popular VPN software out there and for a good reason. ' +
							'Has many years of active development and is a perfect choice when ' +
							'features and customizations are more important than performance.'
						}
						platformFeatures={[
							'No logs',
							'Use your own server',
							'We store nothing',
							'One-time payment',
							'Use open-source tools',
							'Transparent process',
							'Privacy comes first',
							'Affordable'
						]}
						features={[
							'Open source',
							'Actively maintained',
							'Client applications',
							'Highly configurable',
							'Works on all OSs',
							'Good performance',
						]}
						disadvantages={[
							'Higher complexity',
							'Bigger overhead',
						]}
						tags={["privacy", "anonimity"]}
					/>
				</div>
			</div>
		</Wrapper>
	);
};

export default Index;
